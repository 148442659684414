import React from 'react'
import {SpriteAnimator} from 'react-sprite-animator'
import styled from 'styled-components'

interface SpriteImageProps {
  source: string
  widthFrame: number
  heightFrame: number
  steps: number
  fps: number
}

const SpriteImage = ({
  source,
  widthFrame,
  heightFrame,
  steps,
  fps,
}: SpriteImageProps) => {
  return (
    <Container>
      <SpriteAnimator
        sprite={source}
        width={widthFrame * 3}
        height={heightFrame * 3}
        scale={steps}
        fps={fps}
        frameCount={4}
      />
    </Container>
  )
}

export default SpriteImage

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
