import {ReactiveVar} from '@apollo/client'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lodash = require('lodash')
const {isString} = lodash

const getCleanValueForStorage = <T>(
  value:
    | unknown
    | string
    | number
    | ((T & (Record<string, unknown> | null)) | any),
) => {
  return isString(value) ? value : JSON.stringify(value)
}

export const getVarPersisted = async <T>(
  storageName: string,
  reactiveVar: ReactiveVar<T>,
) => {
  let value

  const previousValue = await localStorage.getItem(storageName)

  if (previousValue !== null) {
    try {
      const parsed = await JSON.parse(previousValue)

      value = parsed
    } catch {
      value = previousValue as unknown as T
    }
  }

  value && reactiveVar(value)

  const onNextChange = (newValue: T | undefined | string) => {
    try {
      if (newValue === undefined) {
        localStorage.removeItem(storageName)
      } else {
        localStorage.setItem(storageName, getCleanValueForStorage(newValue))
      }
    } catch (error) {
      console.log('onNextChange Error', error)
    }

    reactiveVar.onNextChange(onNextChange)
  }

  reactiveVar.onNextChange(onNextChange)
}
