import React, {createContext, useCallback, useState} from 'react'

const ModalContext = createContext({
  isModalVisible: false,
  isPopupVisible: false,
  // isWithdrawal: false,
  toggleModalVisible: () => {},
  togglePopupVisible: () => {},
  // toggleWithdrawal: () => {},
})

interface ContextProps {
  children: JSX.Element | JSX.Element[]
}

const ModalContextProvider = ({children}: ContextProps): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  // const [isWithdrawal, setToggleWithdrawal] = useState(false)

  const toggleModalVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  const togglePopupVisible = useCallback(() => {
    setIsPopupVisible(!isPopupVisible)
  }, [isPopupVisible])

  // const toggleWithdrawal = useCallback(() => {
  //   setToggleWithdrawal(!isWithdrawal)
  // }, [isWithdrawal])

  return (
    <ModalContext.Provider
      value={{
        isModalVisible,
        isPopupVisible,
        // isWithdrawal,
        toggleModalVisible,
        togglePopupVisible,
        // toggleWithdrawal,
      }}>
      {children}
    </ModalContext.Provider>
  )
}

export {ModalContext, ModalContextProvider}
