import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

const KakaoLogin = () => {
  const navigate = useNavigate()

  const getToken = async () => {
    window.Kakao.Auth.login({
      success: (res: any) => {
        window.Kakao.API.request({
          url: '/v2/user/me',

          success: (res: any) => {
            console.log('get info', res)
          },
          fail: (err: any) => {
            console.log(err)
          },
        })

        if (sessionStorage.getItem('access_token')) {
          navigate('/main')
          console.log('main', res)
        } else {
          window.Kakao.Auth.setAccessToken(res.access_token)
          navigate('/account')
          sessionStorage.setItem('access_token', res.access_token)
          console.log('account', res)
        }
      },
      fail: (err: any) => {
        console.log(err)
      },
    })
  }

  // useEffect(() => {
  //   getToken()
  // }, [])

  return <div>{''}</div>
}

export default KakaoLogin
