import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import en from './locales/main/en.json'
import es from './locales/main/es.json'
import ja from './locales/main/ja.json'
import ko from './locales/main/ko.json'
import random_en from './locales/random/en.json'
import random_ko from './locales/random/ko.json'

export type LANGUAGE_TYPE = 'ko' | 'ja' | 'en' | 'es' | string

const locales = {
  en: {
    main: en,
    random: random_en,
  },
  ko: {main: ko, random: random_ko},
  ja: {main: ja},
  es: {main: es},
}

const FALLBACK_LNG = 'en'
const systemLocale = 'en'

void i18n.use(initReactI18next).init({
  resources: locales,
  fallbackLng: FALLBACK_LNG,
  lng: systemLocale,
  ns: ['main'],
  defaultNS: 'main',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
