export const SERVER = {
  DOMAIN_CLIENT: 'https://www.mydearsumone.com',
  DOMAIN_TEST: 'https://develop.mydearsumone.com',
  LOCAL_TEST: 'http://localhost:3000',
}

export const ANALYTICS = {
  // 1st release
  access_signInPage: 'access_signInPage_220921',
  expose_createAccountButton: 'expose_createAccountButton_220921',
  expose_confirmMessageButton: 'expose_confirmMessageButton_220921',
  expose_deleteButton: 'expose_deleteButton_220921',
  expose_linkSharing: 'expose_linkSharing_220921',
  expose_messageModalButton: 'expose_messageModalButton_220921',
  expose_myHome: 'expose_myHome_220921',
  expose_storeButton: 'expose_storeButton_220921',
  expose_visitHome: 'expose_visitHome_220921',
  expose_writeMessageButton: 'expose_writeMessageButton_220921',
  onClick_confirmDelete: 'onClick_confirmDelete_220921',
  onClick_createAccount: 'onClick_createAccount_220921',
  onClick_deleteButton: 'onClick_deleteButton_220921',
  onClick_goBackHome: 'onClick_goBackHome_220921',
  onClick_linkSharing: 'onClick_linkSharing_220921',
  onClick_messageListPage: 'onClick_messageListPage_220921',
  onClick_messageModalButton: 'onClick_messageModalButton_220921',
  onClick_myHome: 'onClick_myHome_220921',
  onClick_sendMessage: 'onClick_sendMessage_220921',
  onClick_signInButton: 'onClick_signInButton_220921',
  onClick_store: 'onClick_store_220921',
  onClick_visitHome: 'onClick_visitHome_220921',
  onClick_writeMessageButton: 'onClick_writeMessageButton_220921',

  // 2nd release
  expose_goSignIn: 'expose_goSignIn_220930',
  expose_faqSignIn: 'expose_faqSignIn_220930',
  expose_faqSignOut: 'expose_faqSignOut_220930',
  onClick_goSignIn: 'onClick_goSignIn_220930',
  onClick_faqSignIn: 'onClick_faqSignIn_220930',
  onClick_faqSignOut: 'onClick_faqSignOut_220930',
  onClick_confirmSignOut: 'onClick_confirmSignOut_220930',

  // 3rd release
  visit_petHome: 'visit_petHome_221017',
}

export const NEW_YEAR_ANALYTICS = {
  enter_landing: 'enter_landing',
  sign_up: 'sign_up',
  enter_home: 'enter_home',
  click_goal: 'click_goal',
  click_share_my_home: 'click_share_my_home',
  click_write_message: 'click_write_message',
  upload_message: 'upload_message',
  click_share_my_upload_message: 'click_share_my_upload_message',
}

export const EXTERNAL_LINK = {
  SUMONE_APP_STORE: 'https://apps.apple.com/kr/app/sumone/id1469506430',
  SUMONE_PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.sumone',
  FIREBASE_DYNAMIC_LINK: 'https://links.sumone.co/mydearsumone',
  FEEDBACK_FORM_LINK:
    'https://monymonyteam.notion.site/About-SumOne-42ebef153d444386a131e80c9f89c428?pvs=4',
  GOAL_LINK: {
    ko: 'https://monymonyteam.notion.site/About-SumOne-42ebef153d444386a131e80c9f89c428',
    ja: 'https://monymonyteam.notion.site/About-SumOne-JA-7fdd8556f8e84352af11b6b57dd58e5c',
    en: 'https://monymonyteam.notion.site/About-SumOne-EN-872a9130610f476d98e91ba4163b072b',
    es: 'https://monymonyteam.notion.site/About-SumOne-ES-3b7943b5e6254b05b06fb19f4228a041',
  },

  SNS_LINK: {
    ko: 'https://www.instagram.com/p/C1djOtkplAQ/?igsh=MWVnZDI1Y3J4cnkzOA%3D%3D&img_index=1',
    ja: 'https://www.instagram.com/p/C2BmW1PtTD_/?igsh=MXFsbXdqYXk5bnNjMg==',
    en: 'https://www.instagram.com/p/C2DGQ2bo33S/',
    es: 'https://www.instagram.com/p/C2DGe-6rIXi/',
  },
}
