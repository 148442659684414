import {IMAGES, SPRITE} from '@assets/images/image'
import PublicText, {PublicTextProps} from '@components/atoms/PublicText'
import SpriteImage from '@components/atoms/SpriteImage'
import React from 'react'
import styled from 'styled-components'

interface NoticeScreenProps extends PublicTextProps {
  topText?: string
  bottomText?: string
  source?: string
  subtractHeight?: number
  sprite?: boolean
}

const NoticeScreen = ({
  topText,
  bottomText,
  source,
  subtractHeight,
  sprite,
  textAlign,
  textColor,
  textSize,
}: NoticeScreenProps) => {
  return (
    <Container>
      <SectionContainer subtractHeight={subtractHeight}>
        <PublicText
          textAlign={textAlign}
          textColor={textColor}
          textSize={textSize}>
          {topText}
        </PublicText>
        <ImageContainer>
          {sprite ? (
            <SpriteImage
              source={SPRITE.loading.source}
              widthFrame={SPRITE.loading.widthFrame}
              heightFrame={SPRITE.loading.heightFrame}
              steps={SPRITE.loading.steps}
              fps={SPRITE.loading.fps}
            />
          ) : (
            <Image src={source ? source : IMAGES.loadingScreen} />
          )}
        </ImageContainer>
        <PublicText
          textAlign={textAlign}
          textColor={textColor}
          textSize={textSize}>
          {bottomText}
        </PublicText>
      </SectionContainer>
    </Container>
  )
}

export default NoticeScreen

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const SectionContainer = styled('div')<{subtractHeight?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props =>
    props.subtractHeight ? `calc(100% - ${props.subtractHeight}px)` : '100%'};
`

const ImageContainer = styled.div`
  margin: 20px 0;
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`
