import React, {ReactNode} from 'react'
import styled from 'styled-components'

export interface PublicTextProps {
  children?: ReactNode | string
  textColor?: string
  textAlign?: string
  textSize?: number
  lineHeight?: number
  [key: string]: any
}

const PublicText = ({
  children,
  textColor,
  textAlign,
  textSize,
  lineHeight,
  ...rest
}: PublicTextProps) => {
  return (
    <Text
      textColor={textColor}
      textAlign={textAlign}
      textSize={textSize}
      lineHeight={lineHeight}
      {...rest}>
      {children ? children : ''}
    </Text>
  )
}

export default PublicText

const Text = styled('span')<{
  textColor?: string
  textAlign?: string
  textSize?: number
  lineHeight?: number
}>`
  color: ${props => (props.textColor ? props.textColor : '#000')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  font-size: ${props => (props.textSize ? props.textSize : 19)}px;
  line-height: ${props => props.lineHeight};
  white-space: pre-wrap;
  letter-spacing: 0.364863px;
`
