import {LANGUAGE_TYPE} from '@constants/i18n'
import {createGlobalStyle} from 'styled-components'

import '@kfonts/neodgm'

interface GlobalStyleProps {
  language: LANGUAGE_TYPE
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    a{
        text-decoration:none;
        color: black;
    }
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        border: 0;
        font-size: 100%;
        // font-family: "Neo둥근모", "neodgm";
        font-family: ${props => getFontByLanguage(props.language)};
        vertical-align: baseline;
    }
    body {
        margin: 0 auto;
        line-height: 1.3;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height:100vh;
        background-color: black;
        font-family: ${props => getFontByLanguage(props.language)};
        overflow: scroll;
        background-color: #000180;
        /* 개행 문자(\n) 줄 바꿈처리 */
        white-space: pre-line
    }
    ol, ul, li {
        list-style: none;
    }
    
    button, input, select, textarea {
        font-size: 100%;
        margin: 0;
        vertical-align: baseline;
        font-family: ${props => getFontByLanguage(props.language)};
    }
    
    button, input {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        cursor: pointer;
        outline: 0;
    }
    input:disabled {
        color: #000;
        -webkit-text-fill-color: #000;
        opacity: 1;
    }
`

export default GlobalStyle

const getFontByLanguage = (language: LANGUAGE_TYPE) => {
  return language === 'ja' ? 'PixelMplus' : 'DungGeunMo'
}
