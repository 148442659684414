import {IMAGES} from '@assets/images/image'
import {Option} from '@mui/base/Option'
import {Select, SelectRootSlotProps} from '@mui/base/Select'
import * as React from 'react'

export interface SelectItem {
  value: string
  label: string
}
export type onSelectChange<T = string> = (
  event:
    | React.MouseEvent<Element, MouseEvent>
    | React.KeyboardEvent<Element>
    | React.FocusEvent<Element, Element>
    | null,
  value: T | null,
) => void

interface SelectBoxProps {
  items: SelectItem[]
  selectedValue: string
  onChange: onSelectChange
  defaultListboxOpen?: boolean
}

export default function SelectBox({
  defaultListboxOpen = false,
  items,
  onChange,
  selectedValue,
}: SelectBoxProps) {
  return (
    <>
      <Select
        className="CustomSelect"
        value={selectedValue}
        onChange={onChange}
        slots={{
          root: Button,
        }}
        slotProps={{
          listbox: {className: 'CustomSelect-listbox'},
          popper: {className: 'CustomSelect-popper'},
        }}
        defaultValue={selectedValue}
        defaultListboxOpen={defaultListboxOpen}>
        {items.map(item => (
          <Option
            key={item.value}
            className="CustomSelect-option"
            value={item.value}>
            <SelectText
              text={item.label}
              isActive={selectedValue === item.value}
            />
          </Option>
        ))}
      </Select>
      <Styles />
    </>
  )
}

const Button = React.forwardRef(function Button<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {ownerState, ...other} = props

  return (
    <button
      type="button"
      {...other}
      ref={ref}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <SelectText text={other.children as string} isActive />
    </button>
  )
})

interface SelectText {
  text: string
  isActive: boolean
}

const SelectText = ({text, isActive}: SelectText) => {
  return (
    <>
      <span style={{marginRight: '10px', color: 'white'}}>{text}</span>
      {isActive ? <img src={IMAGES.unionIcon} /> : null}
    </>
  )
}

function Styles() {
  return (
    <style>
      {`
     .CustomSelect {
      display: flex;
      position: relative;
      font-size: 22px;
      color: white
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;
      padding: 0 8px;

      & > img {
        height: 32px
        width: 32px
      }
    }
    .CustomSelect-listbox {
      overflow: auto;
      outline: 0px;
      font-size: 22px;
      color: white
    }
    .CustomSelect-popper {
      z-index: 1000;
      background-color: #000180;
    }
    .CustomSelect-option {
      cursor: default;
      border-bottom: 1px solid white;
      padding: 8px;
      text-align: center;

      &:last-of-type {
        border-bottom: none;
      }
    }
    `}
    </style>
  )
}
