import PublicText from '@components/atoms/PublicText'
import MainHeader from '@components/organisms/MainHeader'
import {TERMS} from '@constants/terms'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

const TermsPage = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <>
      <MainHeader
        headerTitle={t('myPage.termsOfService')}
        right={{type: 'iconButton', onClick: () => navigate(-1)}}
      />
      <TextContainer>
        <PublicText textSize={14} textColor="#fff" lineHeight={1.6}>
          {TERMS.Terms}
        </PublicText>
      </TextContainer>
    </>
  )
}

export default TermsPage

const TextContainer = styled.div`
  width: 100%;
  height: calc(100% - 44px);
  padding: 0 10px;
  padding-bottom: 30px;
  overflow: scroll;
  background-color: #00019f;
`
