import HeaderBox, {
  HeaderBoxProps,
} from '@components/molecules/MainHeaderMenuBox'
import React, {ReactNode} from 'react'
import styled from 'styled-components'

interface MainHeaderProps extends HeaderBoxProps {
  onClickLeft?: () => void
  onClickRight?: () => void
  textSize?: number
  subHeader?: ReactNode
}

const MainHeader = ({
  left,
  right,
  headerTitle,
  textSize,
  subHeader,
}: MainHeaderProps) => {
  return (
    <Container>
      <HeaderBox
        headerTitle={headerTitle ? headerTitle : ''}
        left={left}
        right={right}
        textSize={textSize}
      />
      {subHeader}
    </Container>
  )
}

export default MainHeader

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #000180;
  width: 100%;
  margin-bottom: 10px;
`
