import React, {createContext, useCallback, useState} from 'react'

const AccountContext = createContext({
  nickname: '',
  onChangeNickname: (text: string) => {
    text
  },
})

interface ContextProps {
  children: JSX.Element | JSX.Element[]
}

const AccountContextProvider = ({children}: ContextProps): JSX.Element => {
  const [nickname, setNickname] = useState('')

  const onChangeNickname = useCallback((text: string) => {
    setNickname(text)
  }, [])

  return (
    <AccountContext.Provider value={{nickname, onChangeNickname}}>
      {children}
    </AccountContext.Provider>
  )
}

export {AccountContext, AccountContextProvider}
