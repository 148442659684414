import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
  from,
} from '@apollo/client'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {LoginType} from '@components/pages/LoginPage'
import {SERVER} from '@constants/default'
import {IS_LOGGED_IN_VAR} from '@constants/storage'
import storageUtil from '@utils/storage'
import {PostEdge} from 'src/generated/graphql'

const token = storageUtil.getLocalStorageItem<LoginType>(IS_LOGGED_IN_VAR)
export const isLoggedInVar = makeVar<LoginType | null>(token)
export const userDataVar = makeVar<
  | {
      sessionToken: string
      user: {
        board: {
          uniqueId: string
          totalReceivePostCount: number
          newReceivePostCount: number
          __typename: string
        }
        isNew: boolean
        email: string
        isOfficial: boolean
        nickname: string
        objectId: string
        __typename: string
      }
      __typename: string
    }
  | any
>('')
export const userNicknameVar = makeVar<string | undefined>(undefined)
export const userMessageDetailVar = makeVar<PostEdge | null | any>(null)
export const userInputMessageVar = makeVar<
  | {
      createPost?: {
        __typename?: 'CreatePostPayload'
        post: {
          __typename?: 'Post'
          isArchive?: boolean | null
          isAnonymous?: boolean | null
          isRead?: boolean | null
          fromUserNickname: string
          fromUserUniqueId: string
          content?: string | null
          image: {
            __typename?: 'Image'
            order: number
            name: string
            url: string
          }
        }
      } | null
    }
  | any
>(null)
export const checkGuestInfoVar = makeVar<boolean>(false)
export const checkMasterNameVar = makeVar<string | null>(null)
export const checkMasterInfoVar = makeVar<{
  id?: string | null
  board?: string | null
}>({
  id: '',
  board: '',
})

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development' ||
    window.location.origin === SERVER.DOMAIN_TEST
      ? process.env.REACT_APP_PARSESERVER_GRAPHQL
      : process.env.REACT_APP_PARSESERVER_GRAPHQL,
})

const batchLink = new BatchHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_PARSESERVER_GRAPHQL_TEST
      : process.env.REACT_APP_PARSESERVER_GRAPHQL,
  batchMax: 5,
  batchInterval: 20,
})

export const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      ...headers,
      'X-Parse-Application-Id':
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_TEST_KEY
          : process.env.REACT_APP_CLIENT_KEY,
    },
  }
})

const errorLink = onError(({networkError, graphQLErrors}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
          null,
          2,
        )}, Path: ${JSON.stringify(path, null, 2)}`,
      ),
    )
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    console.log(window.location.origin)
  }
})

export const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: {
            merge(existing, incoming) {
              return incoming
            },
          },
          isLoggedIn: {
            read() {
              return isLoggedInVar()
            },
          },
          userData: {
            read() {
              return userDataVar()
            },
          },
          userNickname: {
            read() {
              return userNicknameVar()
            },
          },
          userMessageDetail: {
            read() {
              return userMessageDetailVar()
            },
          },
          userInputMessage: {
            read() {
              return userInputMessageVar()
            },
          },
          checkMasterInfo: {
            read() {
              return checkMasterInfoVar()
            },
          },
          checkMasterName: {
            read() {
              return checkMasterNameVar()
            },
          },
          checkGuestInfo: {
            read() {
              return checkGuestInfoVar()
            },
          },
        },
      },
    },
  }),
})
