const setLocalStorageItem = (key: string, value: unknown): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getLocalStorageItem = <T>(key: string): T | null => {
  const storedItem = localStorage.getItem(key)

  // local storage에 이미 JSON.stringify 없이 들어간 스트링 데이터를 JSON.parse 하는 경우 오류 발생
  // clear로 해결할 경우 모든 페이지에 추가해야 하므로 추후 리팩토링
  if (typeof storedItem === 'string') {
    return storedItem as any
  }

  return storedItem ? JSON.parse(storedItem) : null
}

const removeLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key)
}

const storageUtil = {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} as const

export default storageUtil
