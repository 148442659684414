import {useReactiveVar} from '@apollo/client'
import {IS_LOGGED_IN_VAR} from '@constants/storage'
import React, {useEffect} from 'react'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import {
  checkMasterInfoVar,
  isLoggedInVar,
  userDataVar,
  userNicknameVar,
} from 'src/graphql/apollo'
import {getVarPersisted} from 'src/graphql/makeVarPersisted'

interface AuthRoutesProps {
  children: JSX.Element
}

const AuthRoutes = ({children}: AuthRoutesProps) => {
  const navigate = useNavigate()
  const board = useParams().board
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  if (window.history.state && window.history.state.idx === 0) {
    checkMasterInfoVar({board: board!})
  }

  if (window.history.state && window.history.state.idx === 0 && board !== '') {
    checkMasterInfoVar({board: board!})

    navigate('/')
  }

  useEffect(() => {
    getVarPersisted(IS_LOGGED_IN_VAR, isLoggedInVar)
    getVarPersisted('userDataVar', userDataVar)
    getVarPersisted('userNicknameVar', userNicknameVar)
  }, [])

  if (localStorage.getItem(IS_LOGGED_IN_VAR)) {
    return children
  } else if (isLoggedIn) {
    return children
  } else {
    return <Navigate to="/" />
  }
}

export default AuthRoutes
