export const IMAGES = {
  addMessageButton: require('@assets/images/addMessageButton.png'),
  anonymousIcon: require('@assets/images/anonymousIcon.png'),
  backIcon: require('@assets/images/backIcon.png'),
  cancelIcon: require('@assets/images/cancelIcon.png'),
  carouselContainer: require('@assets/images/carouselContainer.png'),
  checkIcon: require('@assets/images/checkIcon.png'),
  checkedBox: require('@assets/images/checkedBox.png'),
  closeIcon: require('@assets/images/closeIcon.png'),
  closeIconWhite: require('@assets/images/closeIconWhite.png'),
  downloadIcon: require('@assets/images/downloadIcon.png'),
  errorScreen: require('@assets/images/errorScreen.png'),
  largeButtonContainer: require('@assets/images/largeButtonContainer.png'),
  largeButtonContainerSky: require('@assets/images/largeButtonContainerSky.png'),
  largeHorizontalScroll: require('@assets/images/largeHorizontalScroll.png'),
  largeVerticalScroll: require('@assets/images/largeVerticalScroll.png'),
  leftArrow: require('@assets/images/leftArrow.png'),
  leftIcon: require('@assets/images/leftIcon.png'),
  levelInfo1: require('@assets/images/levelInfo1.png'),
  levelInfo2: require('@assets/images/levelInfo2.png'),
  levelInfo3: require('@assets/images/levelInfo3.png'),
  levelInfo4: require('@assets/images/levelInfo4.png'),
  levelInfo5: require('@assets/images/levelInfo5.png'),
  levelInfo6: require('@assets/images/levelInfo6.png'),
  linkIcon: require('@assets/images/linkIcon.png'),
  loadingScreen: require('@assets/images/loadingScreen.png'),
  mailIcon: require('@assets/images/mailIcon.png'),
  mainBackground: require('@assets/images/mainBackground.png'),
  mainTitle: {
    ko: require('@assets/images/mainTitle_ko.png'),
    ja: require('@assets/images/mainTitle_ja.png'),
    en: require('@assets/images/mainTitle_en.png'),
    es: require('@assets/images/mainTitle_es.png'),
  },
  mediumButtonContainer: require('@assets/images/mediumButtonContainer.png'),
  mediumHeartButton: require('@assets/images/mediumHeartButton.png'),
  mediumVerticalScroll: require('@assets/images/mediumVerticalScroll.png'),
  menuBorderImage: require('@assets/images/menuBorderImage.png'),
  menuIcon: require('@assets/images/menuIcon.png'),
  message01: require('@assets/images/message01.png'),
  message02: require('@assets/images/message02.png'),
  message03: require('@assets/images/message03.png'),
  message04: require('@assets/images/message04.png'),
  message05: require('@assets/images/message05.png'),
  message06: require('@assets/images/message06.png'),
  message07: require('@assets/images/message07.png'),
  message08: require('@assets/images/message08.png'),
  message09: require('@assets/images/message09.png'),
  message10: require('@assets/images/message10.png'),
  message11: require('@assets/images/message11.png'),
  message12: require('@assets/images/message12.png'),
  message13: require('@assets/images/message13.png'),
  message14: require('@assets/images/message14.png'),
  message15: require('@assets/images/message15.png'),
  message16: require('@assets/images/message16.png'),
  messageBackground: require('@assets/images/messageBackground.png'),
  messageBoxContainer: require('@assets/images/messageBoxContainer.png'),
  messageDetailBoxContainer: require('@assets/images/messageDetailBoxContainer.png'),
  messagePreviewBackground: require('@assets/images/messagePreviewBackground.png'),
  nicknameBackground: require('@assets/images/nicknameBackground.png'),
  petHeader: require('@assets/images/petHeader.png'),
  popupContainer: require('@assets/images/popupContainer.png'),
  refreshIcon: require('@assets/images/refreshIcon.png'),
  renameBackground: require('@assets/images/renameBackground.png'),
  rightArrow: require('@assets/images/rightArrow.png'),
  rightIcon: require('@assets/images/rightIcon.png'),
  rightIconWhite: require('@assets/images/rightIconWhite.png'),
  shareIcon: require('@assets/images/shareIcon.png'),
  smallHeartButton: require('@assets/images/smallHeartButton.png'),
  statusContainer: require('@assets/images/statusContainer.png'),
  subTitle: require('@assets/images/subTitle.png'),
  subTitle2: require('@assets/images/subTitle2.png'),
  trashIcon: require('@assets/images/trashIcon.png'),
  uncheckedBox: require('@assets/images/uncheckedBox.png'),
  volumeIcon: require('@assets/images/volumeIcon.png'),
  levelUpPopup: {
    2: {
      ko: require('@assets/images/levelupPopup/2_ko.png'),
      ja: require('@assets/images/levelupPopup/2_ja.png'),
      en: require('@assets/images/levelupPopup/2_en.png'),
      es: require('@assets/images/levelupPopup/2_es.png'),
    },
    3: {
      ko: require('@assets/images/levelupPopup/3_ko.png'),
      ja: require('@assets/images/levelupPopup/3_ja.png'),
      en: require('@assets/images/levelupPopup/3_en.png'),
      es: require('@assets/images/levelupPopup/3_es.png'),
    },
    4: {
      ko: require('@assets/images/levelupPopup/4_ko.png'),
      ja: require('@assets/images/levelupPopup/4_ja.png'),
      en: require('@assets/images/levelupPopup/4_en.png'),
      es: require('@assets/images/levelupPopup/4_es.png'),
    },
    5: {
      ko: require('@assets/images/levelupPopup/5_ko.png'),
      ja: require('@assets/images/levelupPopup/5_ja.png'),
      en: require('@assets/images/levelupPopup/5_en.png'),
      es: require('@assets/images/levelupPopup/5_es.png'),
    },
    6: {
      ko: require('@assets/images/levelupPopup/6_ko.png'),
      ja: require('@assets/images/levelupPopup/6_ja.png'),
      en: require('@assets/images/levelupPopup/6_en.png'),
      es: require('@assets/images/levelupPopup/6_es.png'),
    },
  },
  goalButton: {
    ko: require('@assets/images/goalButton_ko.png'),
    ja: require('@assets/images/goalButton_ja.png'),
    en: require('@assets/images/goalButton_en.png'),
    es: require('@assets/images/goalButton_es.png'),
  },
  expFilled: require('@assets/images/expFilled.png'),
  expNotFilled: require('@assets/images/expNotFilled.png'),
  thumbnailImg: {
    ko: require('@assets/images/thumbnailImg_ko.png'),
    ja: require('@assets/images/thumbnailImg_ja.png'),
    en: require('@assets/images/thumbnailImg_en.png'),
    es: require('@assets/images/thumbnailImg_es.png'),
  },
  // login
  loginBackground: require('@assets/images/login/loginBackground.png'),
  loginPet: require('@assets/images/login/loginPet.png'),
  googleLogin: {
    ko: require('@assets/images/login/googleLoginButton_ko.png'),
    ja: require('@assets/images/login/googleLoginButton_en.png'),
    en: require('@assets/images/login/googleLoginButton_en.png'),
    es: require('@assets/images/login/googleLoginButton_en.png'),
  },
  kakaoLogin: {
    ko: require('@assets/images/login/kakaoLoginButton_ko.png'),
    ja: require('@assets/images/login/kakaoLoginButton_en.png'),
    en: require('@assets/images/login/kakaoLoginButton_en.png'),
    es: require('@assets/images/login/kakaoLoginButton_en.png'),
  },

  // announcement
  announcement: require('@assets/images/announcement.png'),

  // select
  unionIcon: require('@assets/images/unionIcon.png'),
}

export const SPRITE = {
  musicBar: {
    source: {
      ko: require('@assets/images/sprites/musicBar_ko.png'),
      ja: require('@assets/images/sprites/musicBar_ja.png'),
      en: require('@assets/images/sprites/musicBar_en.png'),
      es: require('@assets/images/sprites/musicBar_es.png'),
    },
    widthFrame: 1116,
    heightFrame: 48,
    steps: 7,
    fps: 3,
  },
  pet1: {
    source: require('@assets/images/sprites/pet1.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  pet2: {
    source: require('@assets/images/sprites/pet2.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  pet3: {
    source: require('@assets/images/sprites/pet3.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  pet4: {
    source: require('@assets/images/sprites/pet4.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  pet5: {
    source: require('@assets/images/sprites/pet5.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  pet6: {
    source: require('@assets/images/sprites/pet6.png'),
    widthFrame: 144,
    heightFrame: 144,
    steps: 4,
    fps: 4,
    rows: 1,
  },
  loading: {
    source: require('@assets/images/sprites/loading.png'),
    widthFrame: 375,
    heightFrame: 260,
    steps: 4,
    fps: 3,
  },
  anonymousLoading: {
    source: require('@assets/images/sprites/anonymousLoading.png'),
    widthFrame: 375,
    heightFrame: 260,
    steps: 4,
    fps: 3,
  },
  loginPageTextSprite: {
    source: {
      ko: require('@assets/images/sprites/loginPageTextSprite_ko.png'),
      ja: require('@assets/images/sprites/loginPageTextSprite_ja.png'),
      en: require('@assets/images/sprites/loginPageTextSprite_en.png'),
      es: require('@assets/images/sprites/loginPageTextSprite_es.png'),
    },
    widthFrame: 1500,
    heightFrame: 54,
    steps: 4,
    fps: 3,
    animation: {
      basic: [0, 1, 2, 3],
    },
  },
  mainPageTextSprite: {
    source: require('@assets/images/sprites/mainPageTextSprite.png'),
    widthFrame: 375,
    heightFrame: 40,
    steps: 4,
    fps: 3,
    animation: {
      basic: [0, 1, 2, 3],
    },
  },
  messageListPageTextSprite: {
    source: require('@assets/images/sprites/messageListPageTextSprite.png'),
    widthFrame: 375,
    heightFrame: 40,
    steps: 4,
    fps: 3,
    animation: {
      basic: [0, 1, 2, 3],
    },
  },
  petAdoptionTextSprite: {
    ko: {
      source: require('@assets/images/sprites/petAdoptionTextSprite_ko.png'),
      widthFrame: 1125,
      heightFrame: 54,
      steps: 4,
      fps: 3,
      animation: {
        basic: [0, 1, 2, 3],
      },
    },
    ja: {
      source: require('@assets/images/sprites/petAdoptionTextSprite_ja.png'),
      widthFrame: 1125,
      heightFrame: 30,
      steps: 4,
      fps: 3,
      animation: {
        basic: [0, 1, 2, 3],
      },
    },
    en: {
      source: require('@assets/images/sprites/petAdoptionTextSprite_en.png'),
      widthFrame: 1500,
      heightFrame: 54,
      steps: 4,
      fps: 3,
      animation: {
        basic: [0, 1, 2, 3],
      },
    },
    es: {
      source: require('@assets/images/sprites/petAdoptionTextSprite_es.png'),
      widthFrame: 1500,
      heightFrame: 54,
      steps: 4,
      fps: 3,
      animation: {
        basic: [0, 1, 2, 3],
      },
    },
  },
}
