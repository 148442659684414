import React from 'react'
import styled from 'styled-components'

export interface IconButtonProps {
  size?: number
  source?: string
  hidden?: boolean
  onClick?: () => void
}

const IconButton = ({size, source, hidden, onClick}: IconButtonProps) => {
  return (
    <Container size={size} hidden={hidden} onClick={onClick}>
      <IconImage src={source} />
    </Container>
  )
}

export default IconButton

const Container = styled('div')<{size?: number; hidden?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.size ? props.size : 44)}px;
  height: ${props => (props.size ? props.size : 44)}px;
  opacity: ${props => (props.hidden ? 0 : 1)};
`

const IconImage = styled('img')<{source?: string}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
