import React, {createContext, Dispatch, useContext, useReducer} from 'react'

const initialState: [] = [
  // {
  //   id: 1,
  //   nickname: '이상해씨',
  //   image: IMAGES.message01,
  //   message: '이상해씨 넌 내꺼야!',
  // },
  // {
  //   id: 2,
  //   nickname: '파이리',
  //   image: IMAGES.message02,
  //   message: '파이리 넌 내꺼야!',
  // },
  // {
  //   id: 3,
  //   nickname: '꼬부기',
  //   image: IMAGES.message02,
  //   message: '꼬부기 넌 내꺼야!',
  // },
  // {
  //   id: 4,
  //   nickname: '피카츄',
  //   image: IMAGES.message01,
  //   message: '피카츄 넌 내꺼야!',
  // },
  // {
  //   id: 5,
  //   nickname: '뮤츠',
  //   image: IMAGES.message02,
  //   message: '뮤츠 넌 내꺼야!',
  // },
]

type MessageData = {
  id: number
  nickname: string
  image: string
  message: string
}
type Actions =
  | {type: 'ADD'; id: Date; nickname: string; image: string; message: string}
  | {type: 'DELETE'; id: number}

const MessageDataStateContext = createContext<MessageData[] | undefined>(
  undefined,
)

const MessageDataDispatchContext = createContext<Dispatch<Actions> | undefined>(
  undefined,
)

const Reducer = (state: MessageData[], action: Actions): any => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          id: action.id,
          nickname: action.nickname,
          image: action.image,
          message: action.message,
        },
      ]
    case 'DELETE':
      return state.filter(data => data.id !== action.id)
    default:
      state
  }
}

interface ContextProps {
  children: JSX.Element | JSX.Element[]
}

const MessageDataContextProvider = ({children}: ContextProps): JSX.Element => {
  const [messageData, dispatchMessageData] = useReducer(Reducer, initialState)

  return (
    <MessageDataDispatchContext.Provider value={dispatchMessageData}>
      <MessageDataStateContext.Provider value={messageData}>
        {children}
      </MessageDataStateContext.Provider>
    </MessageDataDispatchContext.Provider>
  )
}

export {MessageDataContextProvider}

export const useMessageData = () => {
  const messageData = useContext(MessageDataStateContext)

  if (!messageData) throw new Error('messageData is undefined')

  return messageData.sort((a, b) => b.id - a.id)
}
export const useMessageDataDispatch = () => {
  const dispatchMessageData = useContext(MessageDataDispatchContext)

  if (!dispatchMessageData) throw new Error('dispatchMessageData is undefined')

  return dispatchMessageData
}
