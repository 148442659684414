import {ApolloProvider} from '@apollo/client'
import '@constants/i18n'
import {firebaseConfig} from '@utils/firebaseConfig'
import {initializeApp} from 'firebase/app'

import React from 'react'
import ReactDOM from 'react-dom/client'
import {useTranslation} from 'react-i18next'
import {AccountContextProvider} from 'src/contexts/AccountContext'
import {MessageDataContextProvider} from 'src/contexts/MessageDataContext'
import {client} from 'src/graphql/apollo'
import styled from 'styled-components'
import {ModalContextProvider} from './contexts/ModalContext'
import Routers from './routes/Routes'
import GlobalStyle from './styles/GlobalStyles'
import './styles/font.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Container = styled.div`
  background-color: #000180;
  min-width: 280px;
  max-width: 375px;
  width: 100vw;
  height: 100vh;
  max-height: 900px;
  overflow: hidden;
`

//initialize firebase
initializeApp(firebaseConfig)

const App = () => {
  const {i18n} = useTranslation()

  return (
    <ApolloProvider client={client}>
      <MessageDataContextProvider>
        <AccountContextProvider>
          <ModalContextProvider>
            <GlobalStyle language={i18n.language} />
            <Container>
              <Routers />
            </Container>
          </ModalContextProvider>
        </AccountContextProvider>
      </MessageDataContextProvider>
    </ApolloProvider>
  )
}

root.render(<App />)
