export const TERMS = {
  Terms: `이용약관
제 1조 목적
본 약관은 모니모니(이하 ‘회사’)이 제공하는 서비스(회원이 PC,모바일 등의 각종 디지털 기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 모든 서비스 의미) 를 이용하는데 필요한 회사와 회원간의 절차 및 이용조건, 권리, 의무 및 책임사항 등 기타 필요한 사항들을 규정함을 목적으로 합니다.

제2조 약관의 명시 및 효력
1. 본 약관의 내용은 회사가 제공하는 모든 서비스에 게시하여 공시합니다.
2. 회사는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 회사는 약관이 변경되는 경우에 변경된 약관의 내용과 시행일을 정하여 시행일 최소 7일 이전부터 시행일 후 상당한 기간 동안 공지하고, 기존 이용자에게는 변경된 약관과 적용일자를 이메일 또는 문자 메세지 등 합리적이고 수용가능한 방법으로 변경사항을 공지 또는 통지합니다.
3. 회원은 변경된 약관에 동의하지 않은 권리가 있으며, 시행일로부터 7일 내 변경된 약관에 대해 거절의 의사를 표시하지 않았을 때 본 약관에 동의한 것으로 간주합니다.

제3조 약관 외 준칙
회사는 본 이용약관 및 개별 서비스의 운영정책(이하 ‘운영정책’)을 정할 수 있으며, 본 약관에서 규정된 내용이 개별 서비스 약관에서 정한 운영정책과 충돌하는 경우 개별 서비스의 약관 규정을 우선하여 적용합니다. 본 약관에 규정되지 않은 사항에 대해서는 별도의 운영정책, 회사의 공지, 이용안내, 상관행, 관계법령에서 정한 바를 따릅니다.

제 4조 서비스의 구분
1. 회사가 회원에게 제공하는 무료서비스, 유료서비스, 개별서비스 등 모든 서비스를 “서비스” 라 고 칭합니다.
2. 무료서비스, 유료서비스 등의 종류와 이용방법 등은 이 약관 및 회사가 공지 또는 이용안내에서 별도로 정하는 바에 따릅니다.

제 5조 이용계약의 성립
1. 이용자가 로그인 시에는 약관에 대한 동의로 간주합니다. 회원 등록에 필요한 필수사항(닉네임)을 입력한 후 회원등록을 완료하면 계정이 생성되는 절차로 진행됩니다.
2. 회사가 지정하는 타서비스계정(카카오톡 계정 간편 로그인)을 활용하여 본 서비스의 계정을 생성할 수 있습니다. 회사는 가입신청자의 신청에 대하여 서비스 이용 승낙을 원칙으로 합니다. 이용 승낙을 통해 서비스를 자유롭게 이용할 수 있습니다.

제6조 계정 생성 거절 및 승낙 보류
1. 회사는 아래와 같은 경우 이용계약을 거절 및 철회할 수 있습니다.
가. 타인 명의의 계정 및 개인정보를 이용하여 계정 생성한 경우
나. 공서양속을 저해하는 표시의 닉네임을 사용하는 경우
다. 회원의 귀책사유로 이용 승낙이 곤란한 경우
2. 회사는 전항의 각호에 사유가 해당하는 경우, 즉시 사용자의 서비스 이용을 중단하거나 계정을 삭제하는 등 적절한 서비스 제한을 할 수 있습니다.
3. 회사는 아래와 같은 경우에 이용계약을 보류할 수 있습니다.
가. 제공 서비스 설비 용량에 현실적인 여유가 없는 경우나. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우다. 기타 회사가 재정적, 기술적으로 사용제한이 필요하다고 인정하는 경우

제7조 아이디 부여 및 관리
1. 회사는 회원이 신청한 내용 그대로 아이디를 부여합니다.
2. 회원이 생성한 아이디는 필요에 따라 수정이 가능합니다.
3. 아이디가 아래 각 호에 해당하는 경우 회사는 회원에게 서비스 제공을 중단하고 새로운 아이디로 이용할 것을 권장할 수 있습니다.
가. 회원의 사생활 침해의 우려가 있는 경우
나. 타인에게 혐오감 및 불쾌감을 주거나 공서양속을 해치는 경우
다. 기타 회원의 보호를 위한 합리적인 사유가 있는 경우
4. 회원이 생성한 계정의 정보는 회원 본인만 이용할 수 있으며, 다른 사람이 계정 정보를 사용하여 동일한 계정의 서비스를 이용할 수 없습니다. 회원 본인을 제외한 다른 사람이 계정을 무단으로 사용할 수 없도록 회원이 직접 관리하여야 합니다.
5. 서비스 내 별도 페이지를 통해 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스의 제공 및 관리를 위해 계정 정보는 수정이 불가능합니다. 서비스 이용 신청 시 직접 작성한 내용 및 계정에 변동이 있을 때, 직접 수정하여야 합니다.

제8조 회사의 의무
1. 회사는 회원으로부터 제기되는 의견 및 불만사항이 정당하다고 인정할 경우 즉시 처리하고, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 안내해야 합니다.
2. 회사는 지속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실 된 때에는 지체없이 수리 또는 복구하며, 아래 각 호의 사유 발생 시 부득이한 경우 예고없이 서비스의 전부 또는 일부의 제공을 일시 중지할 수 있습니다. 그 경우 사유 및 중지 기간 등을 회원에게 사후 공지합니다.
가. 회사가 긴급한 시스템 점검, 증설, 교체, 시설의 보수 또는 공사를 위해 부득이한 경우
나. 새로운 서비스 시스템으로의 교체 등을 위하여 회사가 필요하다고 판단한 경우
다. 시스템 또는 기타 서비스의 설비와 장애, 유무선 네트워크 장애 등으로 정상적인 서비스 제공이 불가능한 경우
라. 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유가 발생한 경우

제9조 개인정보보호
회사는 회원들의 개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 전기통신사업법 등 관련 법규를 준수하고 있습니다. 회사는 회원이 제공한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떤 조치가 취해지고 있는지 알려드립니다.

제10조 회원의 의무
1. 회원가입 시 허위 또는 타인의 정보를 등록한 경우 회사에 대하여 서비스 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여 발생하는 손해에 대하여 책임을 부담하지 않습니다.
2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반규정 및 공지사항을 준수하여야 합니다. 회원은 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.
3. 회원은 청소년보호법 등 관계법령을 준수하여야 합니다. 회원이 청소년보호법 등 관계법령을 위반하는 경우 해당 법령에 의거하여 처벌을 받을 수 있고, 회사에서는 책임지지 않습니다.
4. 회원은 생성된 계정을 직접 관리해야 합니다. 관리소홀로 발생한 문제는 회사에서는 책임지지 않습니다.
5. 회원은 계정, 닉네임, 기타 서비스 내에서 사용되는 명칭을 아래에 해당하는 내용으로 사용하여서는 안됩니다.
가. 서비스 공식 운영자를 사칭하거나 유사한 명칭을 사용하여 혼란을 초래하는 행위
나. 선정적이고 음란한 내용이 포함된 명칭의 사용
다. 제3자의 상표권, 저작권 등 권리를 침해할 가능성이 있는 명칭의 사용
라. 비어, 속어라고 판단되거나 반사회적이고 관계법령에 저촉되는 내용이 포함된 명칭의 사용
6. 회원은 서비스의 이용권한, 기타 계약상의 지위를 타인에게 매도 및 증여할 수 없으며 무형자산을 담보로 제공할 수 없습니다.
7. 회원은 회사에서 제공하는 서비스의 본래의 목적 이외의 용도로 사용해서는 안됩니다. 아래 해당하는 경우 서비스 이용을 제한하거나 계정삭제, 수사기관의 고발조치 등 제재를 가할 수 있습니다.
가. 회원가입 또는 변경 시 허위정보를 기재하는 행위
나. 타인의 개인정보를 도용하거나 부정하게 사용하는 행위
다. 회원의 계정을 타인에게 매매하는 행위
라. 회사의 운영진 또는 직원을 사칭하는 행위
마. 회사의 서버 해킹 및 클라이언트 프로그램 변경하는 행위
바. 회사 서비스의 버그를 악용하는 행위
사. 회사의 사전 승낙없이 영업활동으로 사용하는 행위
아. 다른 회원을 위협, 희롱, 고통, 피해, 불편을 주는 행위
자. 회사의 승낙 및 권한 없이 회사 서비스 관련 프로그램을 배포하거나 사용을 권장하는 행위
차. 회사의 서비스에 고의적으로 방해하는 일체의 행위
카. 회사의 서비스를 통하여 얻은 정보를 회사의 사전 승낙없이 복제, 출판, 방송, 광고, 제3자에게 제공하는 행위
타. 공공질서 및 미풍양속에 위반되는 행위를 지속하거나 음란한 내용의 정보,문장,음향,동영상,이미지 등 유포하는 행위
파. 본 약관을 포함하여 기타 회사가 정한 제반규정 또는 이용조건을 위반하는 행위 및 기타 관계 법령에 위배되는 행위

제11조 서비스의 제한 및 제공중단
1. 회사는 천재지변, 전시 등 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
2. 회사에서 제공하는 무료서비스는 회사의 경영정책 등의 사유로 서비스의 전부 또는 일부가 제한되거나 중지될 수 있으며, 유료로 전환될 수 있습니다.
3. 회사는 서비스의 이용을 제한하거나 정지하는 때와 무료서비스를 유료로 전환하는 때에는 그 사유 및 제한기간, 예정일시 등을 지체 없이 사전 또는 사후에 회원에게 안내합니다.
4. 회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는 경우 "정보통신망 이용촉진 및 정보보호에 관한 법률"의 규정에 의하여 회원정보 및 계정을 영구적으로 삭제할 수 있습니다. 단, 유료결제 상품을 보유하고 있을 경우 삭제 대상에서 제외되며 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정기간 동안 회원정보를 보관합니다.
5. 회사는 회원이 유료서비스의 결제 요금 등을 지정한 기일로부터 1개월 이상 납부하지 않을 경우에는 전부 또는 일부의 서비스제공을 중단할 수 있습니다.
6. 회사는 회원이 아래에 해당하는 경우 이용고객의 서비스 이용을 일부 또는 전부 제한할 수 있습니다. 회원의 이용을 제한하는 경우, 제한의 종류 및 기간 등 구체적인 기준은 회사의 공지 및 이용안내 등에서 별도로 정하는 바에 따릅니다.
가. 다량의 정보를 전송하여 서비스의 안정적 운영을 방해하는 경우
나. 수신자의 의사에 반하는 광고성 정보, 욕설, 성희롱 등 지속적으로 전송하는 경우
다. 정보통신설비의 오작동이나 정보의 파괴를 유발하는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
라. 제3자의 지적재산권을 침해하는 경우
마. 다른 이용고객의 계정을부정하게 사용하는 경우
바. 전기통신관련법령등을 위반하는 행위를 하는 경우
7. 회원은 이용 정지의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다. 이용제한을 해제하고자 하는 때에는 그 사유, 일시 및 기간 등을 상세하게 작성하여 고객센터 이메일로 접수할 수 있습니다.
8. 회사는 이용정지 기간 중에라도 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지조치를 즉시 해제할 수 있습니다.
9. 회사는 회원의 과실이 없었음을 입증된 경우에는 회원이 이용중인 유료 상품에 대한 이용기간을 정지된 기간만큼 연장해주도록 합니다.

제12조 손해배상
1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다.
2. 회사는 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 여러분의 손해에 대하여는 책임을 부담하지 않습니다.
3. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
가. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
나. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우
다. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
라. 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해
마. 제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해
바. 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
사. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해
아. 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
4. 회사는 회사의 중대한 귀책사유로 유료서비스를 제공하지 못 하는 경우, 공정거래위원회가 고시한 소비자분쟁해결기준에 따라 보상합니다.

제13조 면책
1. 회사는 회원에게 서비스 제공으로부터 기대되는 수익 또는 이익을 얻지 못하는 부분과 관련하여 책임을 지지 않습니다.
2. 회사는 제3자가 게시 또는 전송한 컨텐츠 및 자료로 인하여 회원에게 발생한 손해에 대하여 책임을 지지 않습니다.
3. 회사는 회원 상호간 또는 회원과 제3자간의 서비스를 매개로 발생한 분쟁에 대하여 개입할 의무 및 손해를 배상할 책임을 지지 않습니다.
4. 회사는 광고주의 판촉활동에 회원이 참여하거나, 거래의 결과로서 발생하는 손실 또는 손해에 대해서는 책임을 지지 않습니다.
5. 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.
6. 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용 일체에 관여하는 책임을 지지 않습니다.

제14조 컨텐츠 및 게시물 등의 관리
1. 회사는 건전한 온라인 통신 문화 및 효율적인 서비스 운영을 위하여 게시물 삭제, 비공개, 등록거부, 이동 등 적절한 조치를 취할 수 있습니다.
2. 회사는 회원이 등록한 게시물이 제3자로부터 명예훼손, 지적재산권 등 권리침해를 이유로 게시중단 요청을 받을 경우 임시로 게시 중단할 수 있으며, 게시중단 요청자와 게시물 등록자 간의 소송 및 합의 기타 이에 준하는 관련기관의 결정 등이 회사에 접수될 경우 이에 따릅니다.

제15조 서비스 제공 및 이용
1. 회사는 인터넷과 모바일로 이용할 수 있는 다양한 서비스를 제공합니다. 모바일 웹브라우저를 통하여 접속하여 서비스를 이용할 수 있습니다.
2. 회사는 다양한 서비스를 시시각각 제공하기 때문에 서비스의 자세한 내용은 별도로 안내합니다.
3. 회사는 서비스를 자유롭게 이용할 수 있도록 이에 필요한 소프트웨어를 제공합니다. 단, 제공한 소프트웨어는 회사 소유의 무상의 라이선스를 회원에게 제공합니다. 따라서 회원이 타인에게 해당 소프트웨어 및 라이선스를 전세계를 대상으로 개인적인 양도는 불가능합니다. 회사의 상표 및 로고를 사용할 권리를 부여하지는 않습니다.
4. 회사는 더 나은 서비스를 위하여 필요한 소프트웨어의 업데이트 버전을 제공할 수 있습니다. 소프트웨어의 업데이트에는 중요한 기능의 추가 또는 불필요한 기능의 제거 등이 포함되어 있습니다. 회원은 원활한 서비스 이용을 위하여 꾸준히 서비스 업데이트를 하여야 합니다.
5. 회사는 서비스의 이용과 관련된 각종 약관 및 공지 내용을 서비스 내 개별 메뉴를 통하여 노출하는 방식으로 안내합니다.
6. 서비스 이용 과정에서 발생하는 데이터 통신요금은 회원의 개인 비용으로 회원의 책임하에 이동통신사에 납부 하셔야 합니다. 데이터 통신요금에 대한 자세한 안내는 가입하신 이동통신사에 문의하시기 바랍니다.

제16조 서비스 이용 방법 및 주의점
1. 잘못된 방법으로 서비스의 제공을 방해하거나 회사가 안내하는 방법 이외의 다른 방법을 사용하여 서비스에 접근할 수 없습니다.
2. 회원정보를 무단으로 수집, 이용하거나 다른 사람들에게 제공하는 행위, 서비스를 영리 목적으로 이용하는 것, 음란 정보나 저작권 침해 정보 등 사회질서 및 법령에 위반되는 내용의 정보 등을 발송하거나 게시하는 행위도 금지됩니다.
3. 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위도 금지됩니다.
4. 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도 및 증여할 수 없으며, 담보로 제공할 수 없습니다.
5. 회사의 모든 약관 또는 정책 및 관련 법령을 준수하지 않는다면, 회사는 여러분의 위반행위 등을 조사하거나 여러분의 서비스 이용을 잠시 또는 계속하여 중단하거나, 회원탈퇴 및 재가입에 제한을 둘 수 있습니다.
6. 회사는 법령에서 정하는 기간 동안 회원이 서비스를 이용하기 위해 로그인 혹은 접속한 기록이 없는 경우 여러분이 등록한 이메일 및 개인정보 등 기타 유효한 수단으로 통지 후 여러분의 정보를 파기하거나 분리 보관할 수 있습니다.
7. 서비스 이용을 위한 필수적인 정보가 부족할 경우 이용계약이 중도 해지될 수 있습니다.

제17조 권리의 귀속 및 저작물의 이용
1. 회원은 사진, 글, 정보, (동)영상, 서비스 또는 회사에 대한 의견이나 제안 등 콘텐츠(이하 ‘게시물’)를 서비스에 직접 게시할 수 있으며, 게시물 및 제휴계약에 따라 제공된 저작물 등의 지적재산권은 작성자에게 있습니다. 회사에서는 게시된 게시물이 타인의 지적재산권을 포함한 법률 기타 제3자의 권리침해 게시물인 사실을 확인한 경우 이를 사전예고 없이 비공개 및 삭제하는 등의 조치를 취할 수 있는 권리를 보유합니다.
2. 게시물 업로드 시 부적절한 방법으로 시도하거나 타인의 권리침해 등의 서비스를 방해할 경우, 회원 계정에 대해 경고, 비공개, 일시 또는 영구 이용정지, 탈퇴/재가입 차단 등 회원에게 예고없이 운영상 제재를 즉시 가할 수 있습니다.
3. 게시한 게시물의 모든 문제에 대해서는 게시자가 책임을 부담하게 됩니다. 또한, 음란하거나 폭력적이거나 기타 사회질서 및 법령에 위반하는 게시물을 공개 또는 게시할 수 없습니다.
4. 회사는 게시물이 법령 및 서비스 정책에 위반된다고 판단할 경우, 즉시 비공개 및 삭제하거나 게시를 거부할 수 있습니다.
5. 다른 이용자의 게시물을 공유 및 외부에 사용하기 위해서는 게시물 소유자에게 별도로 허락을 받아야 합니다.

제18조 서비스의 변경 및 종료
1. 회사는 서비스를 24시간, 365일 제공하기 위하여 최선의 노력합니다.
2. 회사는 장비의 유지∙보수를 위한 정기 또는 임시 점검 또는 다른 이유로 서비스의 제공이 일시 중단될 수 있으며, 관련된 공지는 서비스 내 공지합니다. 단, 회사도 예측할 수 없는 이유로 서비스가 중단된 때에는 회사가 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하고, 관련 내용을 서비스 복구 후 공지하도록 노력합니다.
3. 회사의 서비스 제공을 위해 계약한 제3자 업체와의 계약 종료 및 변경, 신규서비스 개시 등의 사유로 서비스의 내용이 변경되거나, 서비스가 종료될 수도 있습니다. 서비스 변경 사항 또는 종료는 서비스 내 공지 및 이메일 발송, 알림 등으로 공지합니다.

제19조 준거법 및 재판관할
1. 본 약관과 관련된 사항에 대하여 대한민국 준거법으로 합니다.
2. 회사와 회원간에 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.

제20조 고지의 의무
본 이용약관 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 서비스 내 공지를 통하여 고지할 것입니다.
– 공고일자 : 2022 년 09 월 21 일
– 시행일자 : 2022 년 09 월 21 일`,
}
